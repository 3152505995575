/* global classificationFilterComponent*/
class ModaClassificationFilterComponent extends classificationFilterComponent {
    getWatch() {
        const superWatch = super.getWatch();
        superWatch['display'] = function (newValue, oldValue) {
            if(this.filterField && newValue) {
                this.emitEvent("filter-menu-open",this.filterField.ItemField);
            }
        };
        return superWatch;
    }

    mounted() {
        return async function () {
            let self = this;
            this.fetchData = this.fetchData.bind(this);
            this.eventMenuClose = this.eventMenuClose.bind(this);
            this.subscribeEvent("category-select",this.fetchData);
            this.subscribeEvent("filter-menu-open",this.eventMenuClose);
            this.display = this.open_by_default;
            $(window).resize(function () {
                self.isMobile = $(window).width() <= 560;
                if(self.isMobile)
                    self.display = false;
            });
        };
    }

    eventMenuClose(menuName) {
        if(this.filterField.ItemField !== menuName  && this.display)
            this.display = false;
    }

    getMethods() {
        const superMethods = super.getMethods();
        superMethods.eventMenuClose = this.eventMenuClose;
        return superMethods;
    }

    getTemplate() {
        return `<div class="filter classificationFilter" >
                   <div>
                        <p class="filter-title" @click="display = !display">
                            {{tr(filterField.ItemField)}}
                            <span v-show="display" class="right"><i class="icon fas fa-minus"></i></span>
                            <span v-show="!display" class="right"><i class="icon fas fa-plus"></i></span>
                        </p>
                    </div>
                    <section class="filter-options" :class="{'visible-filter': display}" @click="display = !isMobile" >
                        <ul class="unstyled">
                          <template v-for="filterClass of collections">
                                <li :key="'li'+filterClass.name" v-if="filterClass.itemCount>0">
                                    <div class="custom-control custom-checkbox">
                                      <input :key="'input'+filterClass.name" class="custom-control-input" type="checkbox" v-model="filterClass.active" :id="filterClass.name" :name="filterClass.name"  @click="launchFilter(filterClass)"/>
                                      <label :for="filterClass.name" class="custom-control-label" >{{filterClass.label}} ({{filterClass.itemCount}})</label>
                                    </div>
                                </li>
                          </template>
                        </ul>
                    </section>
               </div>`;
    }
}

ModaClassificationFilterComponent.registerComponent();